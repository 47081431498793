var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { domainService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var CollectionCaseService = /** @class */ (function (_super) {
    __extends(CollectionCaseService, _super);
    function CollectionCaseService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param data
     * @param loading
     */
    CollectionCaseService.prototype.stopCollectionCase = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.stopCollectionCase,
            data: data,
            loading: loading,
        });
    };
    /**
     * 退案
     * @param data
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.backCollectionCase = function (idList, model, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.backCollectionCase,
            data: {
                idList: idList,
                endCaseDate: FilterService.dateFormat(model.endCaseDate, "yyyy-MM-dd"),
            },
            loading: loading,
        });
    };
    /**
     *
     * @param data
     * @param page
     * @param sort
     * @param loading
     */
    CollectionCaseService.prototype.updateComment = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.updateComment,
            data: {
                id: data.id,
                commentContent: data.commentContent,
                caseId: data.caseId,
            },
            loading: loading,
        });
    };
    /**
     * 执行页案件查询
     * @param collectionId 案件Id
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.caseCollectionSearch = function (collectionId, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.caseCollectionSearch,
            append: [collectionId],
            loading: loading,
        });
    };
    /**
     * 查询调收员调收中的案件
     * @param data 查询参数实体
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.caseCollectionStatus = function (data, leftLogo, page, sort, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        var followTime = FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.collectionCaseController.caseCollectionStatus,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                cardNo: data.cardNo,
                city: data.city,
                phone: data.phone,
                comment: data.comment,
                certificateNo: data.certificateNo,
                currentCollector: data.currentCollector,
                handsNumber: data.handsNumber,
                collectionStatu: data.collectionStatus,
                caseStatus: data.caseStatus,
                leaveFlag: data.caseLeaveFlag,
                deptCode: data.deptCode,
                account: data.account,
                collectionRecordCount: data.collectionRecordCount,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                groupType: data.groupType,
                caseFiller: data.caseFiller,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                payStatus: data.payStatus,
                currentCollectorName: data.currentCollectorName,
                userName: data.userName,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                retireCaseDays: data.retireCaseDays,
                status: leftLogo,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询联系人
     * @param id
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.casePersonalContact = function (id, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.casePersonalContact,
            data: {
                personalId: id,
            },
            loading: loading,
        });
    };
    /**
     * 案件留案
     * @param data 查询参数实体
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.leaveCollectionCase = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.leaveCollectionCase,
            data: {
                id: data.id,
                leaveReason: data.leaveReason,
            },
            loading: loading,
        });
    };
    /**
     * 查询
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序实体
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.queryCollection = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.queryCollection,
            data: {
                id: data.id,
                leaveReason: data.leaveReason,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查询
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序实体
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.queryCollectionCount = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.queryCollectionCount,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询
     * @param data 查询参数实体
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.queryCollectionByPrincipal = function (data, page, sort, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.collectionCaseController.queryCollectionByPrincipal,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                personalName: data.personalName,
                cardNo: data.cardNo,
                city: data.city,
                phone: data.phone,
                comment: data.comment,
                certificateNo: data.certificateNo,
                currentCollector: data.currentCollector,
                handsNumber: data.handsNumber,
                collectionStatu: data.collectionStatus,
                caseStatus: data.caseStatus,
                leaveFlag: data.caseLeaveFlag,
                deptCode: data.deptCode,
                account: data.account,
                collectionRecordCount: data.collectionRecordCount,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                groupType: data.groupType,
                caseFiller: data.caseFiller,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                followDays: data.followDays,
                payStatus: data.payStatus,
                currentCollectorName: data.currentCollectorName,
                userName: data.userName,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                retireCaseDays: data.retireCaseDays,
                isAssist: data.isAssist,
                emailAddr: data.emailAddr,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 金额
     * @param data 查询参数实体
     * @param page 分页参数
     * @param sort 排序参数
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.queryCollectionAmtByPrincipal = function (data, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.collectionCaseController.queryCollectionAmtByPrincipal,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                caseStatus: data.caseStatus,
                leaveFlag: data.caseLeaveFlag,
                personalName: data.personalName,
                cardNo: data.cardNo,
                city: data.city,
                phone: data.phone,
                deptCode: data.deptCode,
                comment: data.comment,
                certificateNo: data.certificateNo,
                currentCollector: data.currentCollector,
                handsNumber: data.handsNumber,
                collectionStatu: data.collectionStatus,
                collectionRecordCount: data.collectionRecordCount,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                groupType: data.groupType,
                caseFiller: data.caseFiller,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                followDays: data.followDays,
                payStatus: data.payStatus,
                currentCollectorName: data.currentCollectorName,
                userName: data.userName,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                isAssist: data.isAssist,
                emailAddr: data.emailAddr,
            },
            loading: loading,
        });
    };
    /**
     * 根据调收员或管理员组织查询相关的委托方
     * @param token
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.searchPrincipal = function (token, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.searchPrincipal,
            data: {
                token: token,
            },
            loading: loading,
        });
    };
    /**
     * 查询不同委托方的全部案件
     * @param data
     * @param sort
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.queryBatchNumberCollection = function (data, sort, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.queryBatchNumberCollection,
            data: data,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 根据客户信息查询公债案件的数量
     * @param data
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.searchDebtCaseCount = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.searchDebtCaseCount,
            data: {
                idCard: data.idCard,
                principalId: data.principalId,
                operBatchNumber: data.operBatchNumber,
            },
            loading: loading,
        });
    };
    /**
     * 添加评语/批注/记事本
     * @param data 记事本实体
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.insertComment = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.insertComment,
            data: data,
            loading: loading,
        });
    };
    /**
     * 查询评语/批注/记事本
     * @param data 查询参数实体
     * @param page 分页参数实体
     * @param sort 排序参数实体
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.searchComment = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.searchComment,
            data: data,
            loading: loading,
        });
    };
    /**
     * 记事本
     * @param data 查询参数实体
     */
    CollectionCaseService.prototype.searchCaseComment = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.searchCaseComment,
            data: data,
            loading: loading,
        });
    };
    /**
     * 标记案件状态
     * @param data
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.signCaseState = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.signCaseState,
            data: {
                caseId: data.caseId,
                collState: data.collState,
            },
            loading: loading,
        });
    };
    /**
     * 删除案件状态
     * @param data
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.deleteCaseState = function (caseId, checkList, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.deleteCaseState,
            data: {
                caseId: caseId,
                deleteState: checkList,
            },
            loading: loading,
        });
    };
    /**
     * 案件查询
     * @param data
     * @param page
     * @param sort
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.getCaseByState = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.getCaseByState,
            data: {
                principalId: data.principalId,
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                state: data.state,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                phone: data.phone,
                city: data.city,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                deleteCaseDateStart: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").start,
                deleteCaseDateEnd: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").end,
                stopTimeStart: FilterService.dateRanageFormat(data.stopTime, "yyyy-MM-dd").start,
                stopTimeEnd: FilterService.dateRanageFormat(data.stopTime, "yyyy-MM-dd").end,
                handsNumber: data.handsNumber,
                leaveFlag: data.leaveFlag,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 个案查询
     * @param data
     * @param page
     * @param sort
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.queryAllCollectionCase = function (data, page, sort, loading) {
        //let followTime = FilterService.dateRanageFormat(data.delegationDate, 'yyyy-MM-dd');
        return this.netService.send({
            server: domainService.collectionCaseController.queryAllCollectionCase,
            data: {
                principalName: data.principalName,
                principalId: data.principalId,
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                phone: data.phone,
                cardNo: data.cardNo,
                address: data.address,
                deptCode: data.deptCode,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                account: data.account,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                city: data.city,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                handsNumber: data.handsNumber,
                leaveFlag: data.leaveFlag,
                currentCollectorName: data.currentCollectorName,
                userName: data.userName,
                collectionStatu: data.collectionStatus,
                payStatus: data.payStatus,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 个案查询
     * @param data
     * @param page
     * @param sort
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.queryAdjustCase = function (data, page, sort, loading) {
        //let followTime = FilterService.dateRanageFormat(data.delegationDate, 'yyyy-MM-dd');
        return this.netService.send({
            server: domainService.collectionCaseController.queryAdjustCase,
            data: {
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                city: data.city,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                overduePeriodsSatrt: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                phone: data.phone,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                deptCode: data.deptCode,
                leaveFlag: data.leaveFlag,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                comment: data.comment,
                retireCaseDays: data.retireCaseDays,
                account: data.account,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                handsNumber: data.handsNumber,
                payStatus: data.payStatus,
                currentCollectorName: data.currentCollectorName,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 快速查询
     * @param data
     * @param page
     * @param sort
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.quickSearch = function (data, page, sort, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.collectionCaseController.quickSearch,
            data: {
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                phone: data.phone,
                comment: data.comment,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                handsNumber: data.handsNumber,
                city: data.city,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                account: data.account,
                principalId: data.principalId,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 历史查询
     * @param data
     * @param page
     * @param sort
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.getHisCase = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.getHisCase,
            data: {
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                caseNumber: data.caseNumber,
                leftAmtStart: data.leftAmtStart,
                leftAmtEnd: data.leftAmtEnd,
                leftAmtDollarStart: data.leftAmtDollarStart,
                leftAmtDollarEnd: data.leftAmtDollarEnd,
                city: data.city,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                deleteCaseDateStart: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").start,
                deleteCaseDateEnd: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").end,
                stopTimeStart: FilterService.dateRanageFormat(data.stopTime, "yyyy-MM-dd").start,
                stopTimeEnd: FilterService.dateRanageFormat(data.stopTime, "yyyy-MM-dd").end,
                handsNumber: data.handsNumber,
                leaveFlag: data.leaveFlag,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 删除案件
     * @param data
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.deleteCaseByIds = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.deleteCaseByIds,
            data: data,
            loading: loading,
        });
    };
    /**
     * 批次删除
     * @param batchNumber
     * @param loading 加载等待
     */
    CollectionCaseService.prototype.deleteCaseByBatchNumber = function (batchNumber, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.deleteCaseByBatchNumber,
            append: [batchNumber],
            loading: loading,
        });
    };
    /**
     * 多维定位
     * @param data
     * @param loading
     */
    CollectionCaseService.prototype.multidimensionalPositioning = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.multidimensionalPositioning,
            data: data,
            loading: loading,
        });
    };
    /**
     * 根据委托方获取批次列表
     */
    CollectionCaseService.prototype.getBatchNumber = function (data) {
        return this.netService.send({
            server: domainService.collectionCaseController.getBatchNumber,
            data: data,
        });
    };
    /**
     * 个案调整根据委托方获取批次列表
     */
    CollectionCaseService.prototype.queryAdjustCaseBatchNumber = function (data) {
        return this.netService.send({
            server: domainService.collectionCaseController.queryAdjustCaseBatchNumber,
            data: data,
        });
    };
    /**
     * 退案案件池 根据委托方获取批次列表
     */
    CollectionCaseService.prototype.getHisBatchNumber = function (data) {
        return this.netService.send({
            server: domainService.collectionCaseController.getHisBatchNumber,
            data: data,
        });
    };
    /**
     * 3.6  公共案件池  根据委托方获取批次列表
     */
    CollectionCaseService.prototype.getPubBatchNumber = function (data) {
        return this.netService.send({
            server: domainService.publicCollectionCaseController.getPubBatchNumber,
            data: data,
        });
    };
    /**
     * 批量永久删除
     */
    CollectionCaseService.prototype.deleteBaseCaseForever = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.deleteBaseCaseForever,
            data: {
                ids: data.idsForever,
                batchNumber: data.batchNumber,
            },
            loading: loading,
        });
    };
    /**
     * 查询共债批次
     * @param data
     */
    CollectionCaseService.prototype.searchDebtBatch = function (data) {
        return this.netService.send({
            server: domainService.collectionCaseController.searchDebtBatch,
            data: data,
        });
    };
    /**
     * 查询共债案件
     */
    CollectionCaseService.prototype.searchDebtCardInfo = function (data) {
        return this.netService.send({
            server: domainService.collectionCaseController.searchDebtCardInfo,
            data: data,
        });
    };
    /**
     * 查询卡信息
     */
    CollectionCaseService.prototype.searchOneCardInfo = function (data) {
        return this.netService.send({
            server: domainService.collectionCaseController.searchOneCardInfo,
            data: data,
        });
    };
    /**
     * 删除评语
     */
    CollectionCaseService.prototype.deleteComment = function (data) {
        return this.netService.send({
            server: domainService.collectionCaseController.deleteComment,
            data: data,
        });
    };
    /**
     * 刷新退案时间
     */
    CollectionCaseService.prototype.changeCaseEndCaseDateByPrincipal = function (data, loading) {
        return this.netService.send({
            server: domainService.collectionCaseController.changeCaseEndCaseDateByPrincipal,
            data: {
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                endCaseDate: FilterService.dateFormat(data.endCaseDate, "yyyy-MM-dd"),
                caseId: data.caseId,
            },
            loading: loading,
        });
    };
    /**
     * 快速调收查询欠款总额和委案总额
     */
    CollectionCaseService.prototype.quickSearchAmt = function (data, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.collectionCaseController.quickSearchAmt,
            data: {
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                phone: data.phone,
                comment: data.comment,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                handsNumber: data.handsNumber,
                city: data.city,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                account: data.account,
                principalId: data.principalId,
            },
            loading: loading,
        });
    };
    /**
     * 个案调整查询欠款总额和委案总额
     */
    CollectionCaseService.prototype.queryAdjustCaseAmt = function (data, loading) {
        //let followTime = FilterService.dateRanageFormat(data.delegationDate, 'yyyy-MM-dd');
        return this.netService.send({
            server: domainService.collectionCaseController.queryAdjustCaseAmt,
            data: {
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                city: data.city,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                overduePeriodsSatrt: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                phone: data.phone,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                deptCode: data.deptCode,
                leaveFlag: data.leaveFlag,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                comment: data.comment,
                retireCaseDays: data.retireCaseDays,
                account: data.account,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                handsNumber: data.handsNumber,
                payStatus: data.payStatus,
                userName: data.userName,
                currentCollectorName: data.currentCollectorName,
            },
            loading: loading,
        });
    };
    /**
     * 全部案件查询欠款总额和委案总额
     */
    CollectionCaseService.prototype.queryAllCollectionCaseAmt = function (data, loading) {
        //let followTime = FilterService.dateRanageFormat(data.delegationDate, 'yyyy-MM-dd');
        return this.netService.send({
            server: domainService.collectionCaseController.queryAllCollectionCaseAmt,
            data: {
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                principalId: data.principalId,
                batchNumber: data.batchNumber,
                city: data.city,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                overduePeriodsSatrt: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                phone: data.phone,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                deptCode: data.deptCode,
                leaveFlag: data.leaveFlag,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                followTimeStart: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").start,
                followTimeEnd: FilterService.dateRanageFormat(data.followTime, "yyyy-MM-dd").end,
                comment: data.comment,
                retireCaseDays: data.retireCaseDays,
                account: data.account,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                handsNumber: data.handsNumber,
                payStatus: data.payStatus,
                userName: data.userName,
                currentCollectorName: data.currentCollectorName,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CollectionCaseService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CollectionCaseService.prototype, "leaveCollectionCase", null);
    __decorate([
        Debounce()
    ], CollectionCaseService.prototype, "insertComment", null);
    return CollectionCaseService;
}(Service));
export { CollectionCaseService };
